import './shared-notes.scss';
import Header from '../../../organisms/header/header.js';
import {Link, Navigate, useLocation, useParams} from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import decodeToken from '../../../../utils/decode-token';
import apiClient from '../../../../utils/apiClient';
import dayjs from 'dayjs';
import { Snackbar, Alert } from '@mui/material';

function RetailerSharedNotes() {
  const { t } = useTranslation();
  const { hacId } = useParams();

  const token = sessionStorage.getItem('token') || null;
  const role = decodeToken(token).role;

  const [text, setText] = useState('');
  const [charCount, setCharCount] = useState(0);
  const [comments, setComments] = useState([]); // Estado para almacenar las notas
  const maxChars = 500;

  // Estado para controlar la snackbar
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success'); // 'success' o 'error'

  const location = useLocation();
  const { breadcrumbName } = location.state || {};

  // Llamada para obtener las notas al cargar la página y después de enviar
  const fetchNotes = async () => {
    try {
      const response = await apiClient.get(`/hac/note?hacId=${hacId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.status === 200) {
        setComments(response.data); // Guardar los comentarios obtenidos
      }
    } catch (error) {
      console.error('Error al obtener las notas:', error);
      setSnackbarMessage(t('shared-notes-error'));
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  // Obtener los comentarios al cargar la página
  useEffect(() => {
    fetchNotes();
  }, [hacId, token, t]);

  // Actualiza el contador de caracteres
  const handleTextChange = (e) => {
    const inputText = e.target.value;
    if (inputText.length <= maxChars) {
      setText(inputText);
      setCharCount(inputText.length);
    }
  };

  // Manejar el envío del comentario
  const handleSubmit = async () => {
    if (text.trim() === '') return; // Evita enviar si el texto está vacío

    const payload = {
      text,
      creation_date: dayjs().format('YYYY-MM-DD HH:mm:ss'),
    };

    try {
      const response = await apiClient.post(`/hac/note?hacId=${hacId}`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        // Reiniciar el textarea después de enviar
        setText('');
        setCharCount(0);

        // Volver a cargar los comentarios del servidor
        fetchNotes();

        setSnackbarMessage(t('shared-notes-success'));
        setSnackbarSeverity('success');
      } else {
        setSnackbarMessage(t('shared-notes-error'));
        setSnackbarSeverity('error');
      }
    } catch (error) {
      console.error('Error al enviar la nota:', error);
      setSnackbarMessage(t('shared-notes-error'));
      setSnackbarSeverity('error');
    } finally {
      setSnackbarOpen(true);
    }
  };

  // Cerrar snackbar
  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <>
      {
        (token && (role === 'retailer' || role === 'retailerGM')) ? (
        <div className="App">
          <Header />
          <main>
            <section className="shared-notes">
              <div className="inner">
                <Link to="/retailer/main/active" className="back-button">
                  <span className="icon-chevron-left"></span>{' '}
                  {t('common-back')}
                </Link>
                <h2>
                  {t('shared-notes-title')}
                  {/*<small>{t('shared-notes-subtitle')}</small>*/}
                </h2>

                <div className="form-control">
                  <textarea
                    className="txt-area"
                    value={text}
                    placeholder={t('shared-notes-placeholder')}
                    onChange={handleTextChange}
                    maxLength={maxChars}
                  ></textarea>
                  <span className="counter">
                    {charCount}/{maxChars}
                  </span>
                </div>
                <div className="actions">
                  <button
                    className="btn primary fit"
                    onClick={handleSubmit}
                  >
                    {t('shared-notes-action')}
                  </button>
                </div>

                {/* Renderizado dinámico de los comentarios, ordenados de más nuevo a más antiguo */}
                <div className="comments">
                  {comments.length > 0 ? (
                    comments
                      .slice() // Hacer una copia del array
                      .sort((a, b) => new Date(b.creation_date) - new Date(a.creation_date)) // Ordenar por fecha
                      .map((comment, index) => (
                        <div className="item" key={index}>
                          <span>{comment.creation_user}</span> /{' '}
                          <span>{dayjs(comment.creation_date).format('DD-MM-YYYY')}</span>{' '}
                          <span>{dayjs(comment.creation_date).format('HH:mm')}</span>
                          <p>{comment.text}</p>
                        </div>
                      ))
                  ) : (
                    <p>{t('shared-notes-no-comments')}</p>
                  )}
                </div>
              </div>
            </section>
          </main>
        </div>
      ) : (
        <Navigate to="/login" />
      )}

      {/* Snackbar para mostrar mensajes de éxito o error */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}
          sx={{ width: '100%' }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
}

export default RetailerSharedNotes;
