import './assets/scss/styles.scss';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import React, { useState } from 'react';
import i18n from 'i18next';
import { I18nextProvider } from 'react-i18next';
import { initReactI18next } from 'react-i18next';
import apiClient from './utils/apiClient';

import esTranslations from './assets/lang/es.json';
import enTranslations from './assets/lang/en.json';
import ptTranslations from './assets/lang/pt.json';
import trTranslations from './assets/lang/tr.json';
import csTranslations from './assets/lang/cs.json';
import skTranslations from './assets/lang/sk.json';

import deDETranslations from './assets/lang/de-DE.json';
import deCHTranslations from './assets/lang/de-CH.json';
import deATTranslations from './assets/lang/de-AT.json';

import frFRTranslations from './assets/lang/fr-FR.json';
import frCHTranslations from './assets/lang/fr-CH.json';
import frBETranslations from './assets/lang/fr-BE.json';
import frLUTranslations from './assets/lang/fr-LU.json';

import nlNLTranslations from './assets/lang/nl-NL.json';
import nlBETranslations from './assets/lang/nl-BE.json';

import itITTranslations from './assets/lang/it-IT.json';
import itCHTranslations from './assets/lang/it-CH.json';


import Login from './components/pages/login/login';
import Welcome from './components/pages/welcome/welcome';
import PasswordRecovery from './components/pages/login/password-recovery/password-recovery';
import PasswordReset from './components/pages/login/password-reset/password-reset';

import Test from './components/pages/test/test';

import MainHac from './components/pages/role-hac/main/main';
import HacExperience from './components/pages/role-hac/main/experience';
import HacExperienceCatalog from './components/pages/role-hac/main/catalog';
import HacBasicInfo from './components/pages/role-hac/profile/basic-info';
import HacVehicles from './components/pages/role-hac/profile/vehicles';
import HacEditVehicle from './components/pages/role-hac/profile/edit-vehicle';
import HacPreferences from './components/pages/role-hac/preferences/preferences';
import HacSettings from './components/pages/role-hac/settings/settings';
import HacLegalPrint from './components/pages/role-hac/legal/print';
import HacLegalPrivacy from './components/pages/role-hac/legal/privacy';
import HacLegalTerms from './components/pages/role-hac/legal/terms';

import MainSuperAdmin from './components/pages/role-super-admin/main/main';
import SuperAdminSettings from './components/pages/role-super-admin/settings/settings';

import MainAdmin from './components/pages/role-admin/main/main';
import AdminSettings from './components/pages/role-admin/settings/settings';
import RetailerDetails from './components/pages/role-admin/retailer-details/retailer-details';
import NominateAdmin from './components/pages/role-admin/nominate/nominate.js';
import NominatingRetailer from './components/pages/role-admin/nominating-retailer/nominating-retailer';
import AssignedPmas from './components/pages/role-admin/asigned-pmas/assigned-pmas';
import HacListAdminPreferences from "./components/pages/role-admin/preferences/preferences";
import AdminSharedNotes from "./components/pages/role-admin/shared-notes/shared-notes";
import NewRetailer from "./components/pages/role-admin/new-retailer/new-retailer";
import NewSaleperson from "./components/pages/role-admin/new-saleperson/new-saleperson";
import NewPma from "./components/pages/role-admin/new-pma/new-pma";
import NewAdmin from "./components/pages/role-admin/new-admin/new-admin";
import NewRetailerGM from "./components/pages/role-admin/new-retailerGM/new-retailerGM";
import DeleteUser from "./components/pages/role-admin/delete-user/delete-user";
import EditRetailerGM from "./components/pages/role-admin/edit-retailerGM/edit-retailerGM";
import EditSaleperson from "./components/pages/role-admin/edit-saleperson/edit-saleperson";
import EditPma from "./components/pages/role-admin/edit-pma/edit-pma";
import EditAdmin from "./components/pages/role-admin/edit-admin/edit-admin";

import MainRetailer from './components/pages/role-retailer/main/main';
import NominateHac from './components/pages/role-retailer/nominate-hac/nominate-hac';
import HacProfile from './components/pages/role-retailer/hac-profile/hac-profile';
import RetailerSettings from './components/pages/role-retailer/settings/settings';
import RetailerVehicles from './components/pages/role-retailer/vehicles/vehicles';
import RetailerEditHacVehicle from './components/pages/role-retailer/vehicles/edit-vehicle';
import RetailerPreferencesHac from "./components/pages/role-retailer/preferences/preferences";


import MainShippingAdmin from './components/pages/role-shipping-admin/main/main';
import ShippingAdminSettings from './components/pages/role-shipping-admin/settings/settings';

import MainPma from './components/pages/role-pma/main/main';
import PmaSettings from './components/pages/role-pma/settings/settings';
import PmaVehicles from './components/pages/role-pma/vehicles/vehicles';
import PmaEditHacVehicle from './components/pages/role-pma/vehicles/edit-vehicle';
import PmaListHacPreferences from './components/pages/role-pma/preferences/preferences';
import RetailerInfo from './components/pages/role-pma/retailer-info/retailer-info';
import PmaInfo from './components/pages/role-pma/pma-info/pma-info';
import PmaPersonalDetailsHac from './components/pages/role-pma/personal-details/personal-details';
import Unenroll from './components/pages/role-pma/unenroll/unenroll';
import PmaSharedNotes from "./components/pages/role-pma/shared-notes/shared-notes";
import DeleteUserPma from './components/pages/role-pma/delete-user/delete-user';
import NewPmaPmaGM from "./components/pages/role-pma/new-pma/new-pma";
import PmaEditPma from "./components/pages/role-pma/edit-pma/edit-pma";

import PrivateRoutes from './utils/private-routes';

import Error404 from './components/pages/error/error404/error404';
import ErrorGeneric from './components/pages/error/generic/error-generic';
import ExpiredInvitation from './components/pages/error/expired-invitation/expired-invitation';
import ErrorMaintenance from './components/pages/error/maintenance/error-maintenance';
import ModalSessionExpired from './components/organisms/modal-session/modal-session.js';
import RetailerSharedNotes from "./components/pages/role-retailer/shared-notes/shared-notes";

const loggedUser = JSON.parse(sessionStorage.getItem('loggedUser'));


i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: enTranslations,
    },
    es: {
      translation: esTranslations,
    },
    pt: {
      translation: ptTranslations,
    },
    de: {
      translation: deDETranslations,
    },
    'de-DE': {
      translation: deDETranslations,
    },
    'de-AT': {
      translation: deATTranslations,
    },
    'de-CH': {
      translation: deCHTranslations,
    },
    fr: {
      translation: frFRTranslations,
    },
    'fr-FR': {
      translation: frFRTranslations,
    },
    'fr-CH': {
      translation: frCHTranslations,
    },
    'fr-BE': {
      translation: frBETranslations,
    },
    'fr-LU': {
      translation: frLUTranslations,
    },
    nl: {
      translation: nlNLTranslations,
    },
    'nl-NL': {
      translation: nlNLTranslations,
    },
    'nl-BE': {
      translation: nlBETranslations,
    },
    it: {
      translation: itITTranslations,
    },
    'it-IT': {
      translation: itITTranslations,
    },
    'it-CH': {
      translation: itCHTranslations,
    },
    tr: {
      translation: trTranslations,
    },
    cs: {
      translation: csTranslations,
    },
    sk: {
      translation: skTranslations,
    },
  },
  lng: loggedUser?.preferred_language || navigator.language,
  fallbackLng: 'en',

  interpolation: {
    escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
  },
});

function App() {
  const [isSessionExpired, setIsSessionExpired] = useState(false);

  const handleSessionExpired = () => {
    setIsSessionExpired(true);
  };

  const handleCloseModal = () => {
    setIsSessionExpired(false);
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('refresh_token');
  };

  apiClient.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response && error.response.status === 401 && !window.location.href.includes('welcome')) {
        //console.log(error.request.responseURL);
        handleSessionExpired();
      }
      return Promise.reject(error);
    }
  );

  return (
    <I18nextProvider i18n={i18n}>
      <Router>
        <Routes>
          <Route element={<PrivateRoutes />}>
            {/* Rutas Retailer*/}
            <Route element={<MainRetailer />} path="/retailer/main" />
            <Route element={<MainRetailer />} path="/retailer/main/active" />
            <Route element={<MainRetailer />} path="/retailer/main/nominated" />
            <Route element={<MainRetailer />} path="/retailer/main/exited" />
            <Route element={<NominateHac />} path="/retailer/nominate-hac" />
            <Route element={<HacProfile />} path="/retailer/hac-profile/:id" />
            <Route element={<RetailerSettings />} path="/retailer/settings" />
            <Route element={<RetailerVehicles />} path="/retailer/vehicles/:hacId" />
            <Route element={<RetailerEditHacVehicle />} path="/retailer/vehicles/new-vehicle" />
            <Route element={<RetailerEditHacVehicle />} path="/retailer/vehicles/edit-vehicle" />
            <Route element={<RetailerPreferencesHac />} path="/retailer/preferences/:hacId" />
            <Route element={<RetailerSharedNotes />} path="/retailer/shared-notes/:hacId" />

            {/* Rutas HAC*/}
            <Route element={<MainHac />} path="/hac/main" />
            <Route element={<HacExperience />} path="/hac/experience" />
            <Route element={<HacExperienceCatalog />} path="/hac/catalog" />
            <Route element={<HacBasicInfo />} path="/hac/profile/basic-info" />
            <Route element={<HacVehicles />} path="/hac/profile/vehicles" />
            <Route element={<HacEditVehicle />} path="/hac/profile/new-vehicle" />
            <Route element={<HacEditVehicle />} path="/hac/profile/edit-vehicle" />
            <Route element={<HacPreferences />} path="/hac/preferences" />
            <Route element={<HacSettings />} path="/hac/settings" />
            <Route element={<HacLegalPrint />} path="/hac/legal/print" />
            <Route element={<HacLegalPrivacy />} path="/hac/legal/privacy" />
            <Route element={<HacLegalTerms />} path="/hac/legal/terms" />

            {/* Rutas PMA*/}
            <Route element={<MainPma />} path="/pma/main" />
            <Route element={<MainPma />} path="/pma/main/hac" />
            <Route element={<MainPma />} path="/pma/main/pma" />
            <Route element={<PmaSettings />} path="/pma/settings" />
            <Route element={<PmaVehicles />} path="/pma/vehicles/:hacId" />
            <Route element={<PmaEditHacVehicle />} path="/pma/vehicles/new-vehicle" />
            <Route element={<PmaEditHacVehicle />} path="/pma/vehicles/edit-vehicle" />
            <Route element={<PmaListHacPreferences />} path="/pma/preferences/:hacId" />
            <Route element={<RetailerInfo />} path="/pma/retailer-info/:id" />
            <Route element={<PmaInfo />} path="/pma/pma-info/:id" />
            <Route element={<PmaPersonalDetailsHac />} path="/pma/personal-details/:hacId" />
            <Route element={<Unenroll />} path="/pma/unenroll/:hacId" />
            <Route element={<PmaSharedNotes />} path="/pma/shared-notes/:hacId" />
            <Route element={<DeleteUserPma />} path="/pma/delete-user" />
            <Route element={<NewPmaPmaGM />} path="/pma/new-pma" />
            <Route element={<PmaEditPma />} path="/pma/edit-pma" />

            {/* Rutas Admin*/}
            <Route element={<MainAdmin />} path="/admin/main" />
            <Route element={<MainAdmin />} path="/admin/main/hac" />
            <Route element={<MainAdmin />} path="/admin/main/pma" />
            <Route element={<MainAdmin />} path="/admin/main/retailer" />
            <Route element={<MainAdmin />} path="/admin/main/admin" />
            <Route element={<RetailerDetails />} path="/admin/retailer-info/:retailerId" />
            <Route element={<NominatingRetailer />} path="/admin/nominating-retailer/:hacId" />
            <Route element={<AssignedPmas />} path="/admin/assigned-pmas/:hacId" />
            <Route element={<AdminSettings />} path="/admin/settings" />
            <Route element={<NominateAdmin />} path="/admin/nominate" />
            <Route element={<AdminSharedNotes />} path="/admin/shared-notes/:hacId" />
            <Route element={<HacListAdminPreferences />} path="/admin/preferences/:hacId" />
            <Route element={<NewRetailer />} path="/admin/new-retailer" />
            <Route element={<NewSaleperson />} path="/admin/new-saleperson/:retailerId" />
            <Route element={<NewPma />} path="/admin/new-pma" />
            <Route element={<NewAdmin />} path="/admin/new-admin" />
            <Route element={<NewRetailerGM />} path="/admin/new-retailerGM/:retailerId" />
            <Route element={<DeleteUser />} path="/admin/delete-user" />
            <Route element={<EditRetailerGM />} path="/admin/edit-retailerGM/:retailerId" />
            <Route element={<EditSaleperson />} path="/admin/edit-saleperson/:retailerId" />
            <Route element={<EditPma />} path="/admin/edit-pma" />
            <Route element={<EditAdmin />} path="/admin/edit-admin" />

            {/* Rutas Super Admin*/}
            <Route element={<MainSuperAdmin />} path="/super-admin/main" />
            <Route element={<SuperAdminSettings />} path="/super-admin/settings" />

            {/* Rutas shippingAdmin*/}
            <Route element={<MainShippingAdmin />} path="/shipping-admin/main" />
            <Route element={<ShippingAdminSettings />} path="/shipping-admin/settings" />
          </Route>

          {/* Rutas Públicas*/}
          <Route element={<Login />} path="/" />
          <Route element={<Login />} path="/login" />
          <Route element={<Login />} path="/login/:logout?" />
          <Route element={<Welcome />} path="/welcome/:token" />
          <Route element={<PasswordRecovery />} path="/password-recovery" />
          <Route element={<PasswordReset />} path="/password-reset/:token" />
          <Route element={<Test />} path="/test" />
          <Route element={<Error404 />} path="*" />
          <Route element={<ErrorMaintenance />} path="/maintenance" />
          <Route element={<ErrorGeneric />} path="/error" />
          <Route element={<ExpiredInvitation />} path="/expired" />
        </Routes>

        <ModalSessionExpired
          open={isSessionExpired}
          handleClose={handleCloseModal}
        />
      </Router>
    </I18nextProvider>
  );
}

export default App;
