import './delete-user.scss';
import Header from '../../../organisms/header/header.js';
import { Link, Navigate, useLocation, useNavigate } from 'react-router-dom';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import decodeToken from '../../../../utils/decode-token';
import Grid from "@mui/material/Unstable_Grid2";
import ModalConfirmDeleteUser from "../../../organisms/role-pma/modal-confirm-delete-user/modal-confirm-delete-user";

function DeleteUser() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const token = sessionStorage.getItem('token') || null;
  const role = decodeToken(token).role;

  const location = useLocation();
  const { breadcrumbName } = location.state || {};

  const userToDelete = location.state?.user || "";
  const userToDeleteRole = location.state?.role || userToDelete.role || "";
  const userToDeleteRetailerName = location.state?.retailer_name || "";

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalData, setModalData] = useState(null);

  const openModal = () => {
    const data = {
      user: userToDelete
    };
    setModalData(data);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setModalData(null);
  };

  return (
    <>
      {token &&
      (role === 'pmaGM') ? (
        <div className="App">
          <Header />
          <main className="delete-user">
            <section className="delete-user">
              <div className="inner">
                <Link
                  to="#"
                  onClick={(e) => {
                    e.preventDefault();
                    navigate(-1);
                  }}
                  className="back-button"
                >
                  <span className="icon-chevron-left"></span> {t('common-back')}
                </Link>
                <h2>{t('admin-delete-user-title')}</h2>
                <p>{t('admin-delete-user-text', { userToDeleteRole: t(`common-role-${userToDeleteRole}`) })}</p>
                <div className="data">
                  <Grid container spacing={3}>
                    <Grid xs={4} sm={4} md={6}>
                      <span>{t('common-name')}</span>
                      <strong>{userToDelete.name} {userToDelete.last_name} {userToDeleteRole}</strong>
                    </Grid>
                    <Grid xs={4} sm={4} md={6}>
                      {(userToDeleteRole === 'saleperson' || userToDeleteRole === 'retailerGM') && (
                        <>
                          <span>{t('common-retailer')}</span>
                          <strong>{userToDeleteRetailerName}</strong>
                        </>
                      )}
                    </Grid>
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid xs={4} sm={4} md={6}>
                      <span>{t('common-email')}</span>
                      <strong>{userToDelete.email}</strong>
                    </Grid>
                    <Grid xs={4} sm={4} md={6}>
                      <span>{t('common-phone')}</span>
                      <strong>{userToDelete.prefix_phone} {userToDelete.phone}</strong>
                    </Grid>
                  </Grid>
                </div>
                <p><strong>{t('admin-delete-warning')}</strong></p>

                <div className="actions">
                  <button className="btn fit" onClick={openModal}>
                    {t('admin-delete-user-action')}
                  </button>
                </div>
              </div>
            </section>
          </main>

          <ModalConfirmDeleteUser
            user={modalData?.user}
            role={userToDeleteRole}
            isOpen={isModalOpen}
            onClose={closeModal}
          />

        </div>
      ) : (
        <Navigate to="/login" />
      )}
    </>
  );
}

export default DeleteUser;
